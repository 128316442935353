import React, { useState, useEffect } from 'react';
import '../styles/Checkout.css';

const Checkout = ({
  handleFormClose,
  checkoutSuccess,
  selectedIds,
}) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [signature, setSignature] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [hashOrderText, setHashOrderText] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessageName, setErrorMessageName] = useState('');
  const [errorMessageAddress, setErrorMessageAddress] = useState('');
  const [errorMessagePhone, setErrorMessagePhone] = useState('');
  const [errorMessageSignature, setErrorMessageSignature] = useState('');

  const calculateHashOrder = async () => {
    const payload = {
      address,
      ids: selectedIds,
      nameRecipient: name,
      phoneNumber: phone,
      payment: selectedCategory,
    };

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await fetch('http://localhost:8080/api/user/hashorder', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setHashOrderText(data.result);
      } else {
        console.error('Failed to calculate hash order');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    calculateHashOrder();
  }, [name, address, phone, signature, selectedCategory]);

  const handleOrderSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let hasError = false;

    if (name === '') {
      setErrorMessageName('Name cannot be empty');
      hasError = true;
    }
    if (address === '') {
      setErrorMessageAddress('Address cannot be empty');
      hasError = true;
    }
    if (phone === '') {
      setErrorMessagePhone('Phone number cannot be empty');
      hasError = true;
    }
    if (signature === '') {
      setErrorMessageSignature('Signature cannot be empty');
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    const payload = {
      address,
      ids: selectedIds,
      nameRecipient: name,
      phoneNumber: phone,
      payment: selectedCategory,
      signature,
    };

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('You need to login first!');
        return;
      }

      const response = await fetch('http://localhost:8080/api/user/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('Order successfully submitted!');
        window.location.reload();
      } else {
        alert('Order submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(hashOrderText).then(() => {
      alert('Hash order copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy:', err);
    });
  };

  return (
    <div className="checkout-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}

      <form onSubmit={handleOrderSubmit} className="checkout-form">
        <h2 className="checkout-heading">Order Information</h2>

        <div className="form-group">
          <label htmlFor="name" className="checkout-label">Name:</label>
          <input
            type="text"
            id="name"
            className="checkout-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <p>{errorMessageName}</p>
        </div>

        <div className="form-group">
          <label htmlFor="address" className="checkout-label">Address:</label>
          <input
            type="text"
            id="address"
            className="checkout-input"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <p>{errorMessageAddress}</p>
        </div>

        <div className="form-group">
          <label htmlFor="phone" className="checkout-label">Phone:</label>
          <input
            type="number"
            id="phone"
            className="checkout-input"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <p>{errorMessagePhone}</p>
        </div>

        <div className="form-group">
          <label htmlFor="signature" className="checkout-label">Signature:</label>
          <input
            type="text"
            id="signature"
            className="checkout-input"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
          />
          <p>{errorMessageSignature}</p>
        </div>

        <div className="form-group">
          <label htmlFor="payment" className="checkout-label">Payment methods:</label>
          <select
            id="payment"
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="Cash">Cash</option>
            <option value="Banking">Banking</option>
            <option value="Visa">Visa</option>
            <option value="Momo">Momo</option>
            <option value="Vnpay">Vnpay</option>
            <option value="Other">Other...</option>
          </select>
        </div>

        <div className="checkout-buttons">
          <button type="submit" className="checkout-submit-btn">Submit</button>
          <button type="button" className="checkout-cancel-btn" onClick={handleFormClose}>Cancel</button>
        </div>

        <div className="checkout-buttons">
          <button type="button" className="checkout-submit-btn" onClick={handleCopy}>Copy HashText</button>
          <input type="text" readOnly className="checkout-input" value={hashOrderText} />
        </div>

        {checkoutSuccess && <p className="checkout-message">Checkout successful!</p>}
      </form>
    </div>
  );
};

export default Checkout;
