import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Account.css';

const Account = () => {
  const [account, setAccount] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('info');
  const [orders, setOrders] = useState([]);
  const [showOrders, setShowOrders] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [resultMessage, setResultMessage] = useState('');
  const [message, setMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [email, setEmail] = useState([]);
  const [privateKey, setPrivateKey] = useState(null); // Lưu key đã tạo
  const [newPrivateKey, setNewPrivateKey] = useState(null); // Lưu private key mới

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    gender: 'male',
    dateOfBirth: ''
  });

  // Thêm state mới
const [oldPassword, setOldPassword] = useState('');
const [newPassword, setNewPassword] = useState('');
const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false); 

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Missing JWT token');
      return;
    }

    if (selectedCategory === 'info') {
      fetchAccount(token);
      setShowOrders(false);
    }
    if (selectedCategory === 'history') {
      fetchOrders(token);
      setAccount(null);
    }
    if (selectedCategory === 'cartItem') {
      fetchCartItems(token);
      setAccount(null);
    }
    if (selectedCategory === 'create-key') {
      setAccount(null);
    }
    if (selectedCategory === 'change-infor') {
      fetchAccount(token);
      
    }
    
  }, [selectedCategory]);

  // Get account information
  const fetchAccount = async (token) => {
    try {
      const response = await axios.get('http://localhost:8080/api/user/account', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Kiểm tra nếu dữ liệu là null hoặc undefined
      const accountData = response.data;
  
      if (accountData && accountData.firstName) {
        setAccount(accountData);  // Cập nhật trạng thái nếu dữ liệu hợp lệ
      } else {
        console.error('Account data is incomplete or null');
        // Xử lý khi không có dữ liệu hoặc dữ liệu không hợp lệ
      }
  
    } catch (error) {
      console.error('Failed to fetch account:', error);
    }
  };
  

  // Get order history
  const fetchOrders = async (token) => {
    try {
      const response = await axios.get('http://localhost:8080/api/user/order', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const ordersData = response.data;
      setOrders(ordersData);
      setShowOrders(true);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    if (categoryId === 'info') {
      fetchAccount(localStorage.getItem('token'));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const handleDeleteOrder = async (orderId) => {    
    const token = localStorage.getItem('token');

    try {
        const response = await axios.delete(`http://localhost:8080/api/user/deleteorder/${orderId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        if (response.status === 200) {
            // Sau khi xóa thành công, fetch lại danh sách đơn hàng
            fetchOrders(token); // Gọi lại hàm fetchOrders để làm mới danh sách đơn hàng
            alert(response.data.result); // Hiển thị thông báo thành công
        } else {
            alert(response.data.result); // Nếu không phải 200, hiển thị thông báo lỗi
        }
    } catch (error) {
        console.error('Failed to delete order:', error);
        alert('Failed to delete order');
    }
};



// const handleClose = async (item) => {
//   try {
//     const token = localStorage.getItem('token');
//     const response = await axios.delete(`http://localhost:8080/api/user/deleteorder/${orderId}`, {
//       method: 'DELETE',
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Failed to delete item');
//     }

//     setData(updatedData);
//   } catch (error) {
//     console.error(error);
//   }
// };





  

const handleOrderDetails = async (orderId) => {
  try {
     const token = localStorage.getItem('token');
     const response = await axios.get(`http://localhost:8080/api/user/order/${orderId}`, {
        headers: {
           Authorization: `Bearer ${token}`,
        },
     });

     const orderData = response.data;
     console.log(orderData);  // Xem dữ liệu trả về trong console

     setSelectedOrder(orderData);  // Lưu dữ liệu đơn hàng vào trạng thái
     setShowOrderDetails(true);    // Hiển thị chi tiết đơn hàng
  } catch (error) {
     console.error('Failed to fetch order details:', error);
  }
};

//get list cart
const fetchCartItems = async (token) => {
  try {
    const response = await axios.get('http://localhost:8080/api/user/cart', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const cartItemsData = response.data;
    setCartItems(cartItemsData);
    console.log(cartItemsData);
  } catch (error) {
    console.error('Failed to fetch cart items:', error);
  }
};
  // Xử lý sự kiện khi người dùng nhấn nút "Submit"
const handleSubmit = (event) => {
  event.preventDefault();

  if (newPassword !== confirmNewPassword) {
    // Hiển thị thông báo lỗi khi mật khẩu mới không khớp
    console.error('New password does not match the confirm password.');
    return;
  }
};
//get list cmt

//change infor
const handleUpdateInformation = async (event) => {
  event.preventDefault();

 

  // Tiến hành gửi yêu cầu cập nhật thông tin đến server
  try {
    const token = localStorage.getItem('token');
    const response = await axios.put(
      'http://localhost:8080/api/user/account',
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('Information updated successfully');
    // Cập nhật lại thông tin tài khoản hiển thị
    setMessage('change info success')
    fetchAccount(token);
  } catch (error) {
    setMessage('change info failer')
    console.error('Failed to update information:', error);
  }
};


  

  const categories = [
    {
      id: 'account',
      label: 'Account Manager',
      subcategories: [
        { id: 'info', label: 'Information' },
        { id: 'change-password', label: 'Change password' },
        { id: 'change-infor', label: 'Update information' },
        { id: 'create-key', label: 'History Comment' },
      ]
    },
    {
      id: 'orders',
      label: 'Order Manager',
      subcategories: [
        { id: 'history', label: 'Order history' },
        { id: 'track', label: 'Order follow' },
      ]
    },
    {
      id: 'cart',
      label: 'Cart Manager',
      subcategories: [
        { id: 'cartItem', label: 'List Cart' },
      ]
    },
    {
      id: 'notification',
      label: 'Notification',
      subcategories: [
        { id: 'order', label: 'Order' },
        { id: 'sale', label: 'Sale' },
        { id: 'update', label: 'Update' },
        { id: 'message', label: 'Message' },
        { id: 'contact', label: 'Contact' },
      ]
    },
    {
      id: 'create-key',
      label: 'Manager Key',
      subcategories: [
        { id: 'create-key', label: '' },
        { id: 'create-key', label: '' },
      ]
    }   
    
  ];

  const handleSubcategoryClick = (subcategory) => {
    if (subcategory.id === 'info') {
      setSelectedCategory('info');
    }
    if (subcategory.id === 'history') {
      setSelectedCategory('history');
    }
    if (subcategory.id === 'cartItem') {
      setSelectedCategory('cartItem');
    }
    if (subcategory.id === 'change-password') {
      setSelectedCategory('change-password');
    }
    
    if (subcategory.id === 'create-key') {
      setSelectedCategory('create-key');
    }
    
    if (subcategory.id === 'change-infor') {
      setSelectedCategory('change-infor');
    }
  };


  const handleChangePassword = async () => {
    const token = localStorage.getItem('token');
    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
      // Kiểm tra tính hợp lệ của mật khẩu cũ và mật khẩu mới
  if (oldPassword.trim() === '') {
    setResultMessage('Please enter your old password.');
    return;
  }

  if (newPassword.trim() === '') {
    setResultMessage('Please enter your new password.');
    return;
  }
  if (newPassword === confirmNewPassword) {
    setResultMessage('Confirm password different.');
    return;
  }

  // Kiểm tra độ dài và sự tồn tại của ít nhất một ký tự viết hoa trong mật khẩu mới
  if (newPassword.length < 8 || !/\d/.test(newPassword)) {
    setResultMessage('New password must be at least 8 characters long and contain at least one decimal digit.');
    return;
  }
  
    try {
      const response = await axios.put('http://localhost:8080/api/user/account/changepassword', payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const result = response.data.result;
      setResultMessage(result);
    } catch (error) {
      console.error('Failed to change password:', error);
    }
  };








  const handleCreateKey = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setResultMessage('Bạn chưa đăng nhập. Vui lòng đăng nhập để tiếp tục.');
      return;
    }
  
    try {
      const response = await axios.get('http://localhost:8080/api/user/account/createkey', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const { result } = response.data;
  
      if (result === "Đã có key") {
        // Hiển thị thông báo khi đã có key
        setResultMessage('Tài khoản của bạn đã có Key. Tại một thời điểm chỉ có một key được sử dụng. Nếu bạn mất key, hãy bấm "Báo mất key".');
      } else {
        // Lưu private key và hiển thị thông báo thành công
        setPrivateKey(result); // Lưu key để tải
        setResultMessage('Tạo key thành công!');
      }
    } catch (error) {
      console.error('Lỗi trong quá trình tạo key:', error);
      if (error.response) {
        setResultMessage(
          error.response.data.message || 'Đã xảy ra lỗi từ phía server. Vui lòng thử lại.'
        );
      } else if (error.request) {
        setResultMessage('Không thể kết nối đến server. Vui lòng kiểm tra kết nối mạng.');
      } else {
        setResultMessage('Đã xảy ra lỗi. Vui lòng thử lại.');
      }
    }
  };

  const handleDownloadKey = () => {
    if (privateKey) {
      const element = document.createElement('a');
      const file = new Blob([privateKey], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'privateKey.txt';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const downloadPrivateKey = () => {
    if (!newPrivateKey) return;
  
    const element = document.createElement('a');
    const file = new Blob([newPrivateKey], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'privateKey.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  



  const handleReportKey = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setResultMessage('Bạn chưa đăng nhập. Vui lòng đăng nhập để tiếp tục.');
      return;
    }
  
    try {
      // Gửi yêu cầu báo mất key
      const response = await axios.put(
        'http://localhost:8080/api/user/account/reportprivatekey',
        {}, // Body request (rỗng vì không cần gửi dữ liệu)
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200) {
        const { result: privateKey } = response.data; // Lấy trường `result` thay vì `message`
        if (privateKey) {
          setResultMessage('Báo mất key thành công. Key mới đã được tạo!');
          setNewPrivateKey(privateKey); // Lưu private key mới vào state
        } else {
          setResultMessage('Báo mất key thành công nhưng không nhận được private key mới!');
        }
      } else {
        setResultMessage('Báo mất key thất bại. Vui lòng thử lại.');
      }
    } catch (error) {
      console.error('Lỗi khi báo mất key:', error);
      if (error.response) {
        setResultMessage(
          error.response.data.message || 'Đã xảy ra lỗi từ phía server. Vui lòng thử lại.'
        );
      } else if (error.request) {
        setResultMessage('Không thể kết nối đến server. Vui lòng kiểm tra kết nối mạng.');
      } else {
        setResultMessage('Đã xảy ra lỗi. Vui lòng thử lại.');
      }
    }
  };
  
  
  



  return (
    <div className="account-container">
      <div className="sidebar">
        <ul className="category-list">
          {categories.map((category) => (
            <li key={category.id} className="category-item">
              <button
                className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
                onClick={() => handleCategoryClick(category.id)}
              >
                {category.label}
              </button>
              {selectedCategory === category.id && (
                <ul className="subcategory-list">
                  {category.subcategories.map((subcategory) => (
                    <li
                      key={subcategory.id}
                      className="subcategory-item"
                      onClick={() => handleSubcategoryClick(subcategory)}
                    >
                      <button className="subcategory-button">{subcategory.label}</button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="content">
     
        {selectedCategory === 'info' && account && (
          <div className="account-info">
          <h2>Account Information</h2>
          <div className="account-data">
            <div className="account-data-row">
              <span className="account-data-label">ID:</span>
              <span className="account-data-value">{account.id}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">First Name:</span>
              <span className="account-data-value">{account.firstName}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Last Name:</span>
              <span className="account-data-value">{account.lastName}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Email:</span>
              <span className="account-data-value">{account.email}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Address:</span>
              <span className="account-data-value">{account.address}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Gender:</span>
              <span className="account-data-value">{account.gender}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Date of Birth:</span>
              <span className="account-data-value">{account.dateOfBirth}</span>
            </div>
            <div className="account-data-row">
              <span className="account-data-label">Account Created:</span>
              <span className="account-data-value">{account.createDate}</span>
            </div>
          </div>
        </div>
        
        )}
         {selectedCategory === 'history' && showOrders && (
  <div className="order-history">
    <h2>Order History</h2>
    <table>
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Recipient</th>
          <th>Address</th>
          <th>Phone Number</th>
          {/* <th>Payment</th> */}
          <th>Create Date</th>
          <th>Total Money</th>
          <th>Status</th> {/* New column for status */}
          <th>Action</th> {/* New column for the action button */}
        </tr>
      </thead>
      <tbody>
      {orders.map((order) => (
  <tr key={order.id} className="order-item">
    <td>{order.id}</td>
    <td>{order.nameRecipient}</td>
    <td>{order.address}</td>
    <td>{order.phoneNumber}</td>
    {/* <td>{order.payment}</td> */}
    <td>{new Date(order.createDate).toLocaleString()}</td>
    <td>{order.totalMoney}</td>
    <td>{order.check}</td> {/* Hiển thị trạng thái */}
    <td>
      {order.check === 'Không xác thực' && (
        <button className="delete-order-btn" onClick={() => handleDeleteOrder(order.id)}>Delete Order</button>
      )}
      <button onClick={() => handleOrderDetails(order.id)}>View Details</button>
    </td>
  </tr>
))}

      </tbody>
    </table>
  </div>
)}


{showOrderDetails && selectedOrder && (
            <div className="order-details-modal">
              <div className="modal-content">
                    <div className="order-details-header">
                      <h3>Order Details</h3>
                      <button onClick={() => setShowOrderDetails(false)}>X</button>
                    </div>

                <table>
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Name</th>
                      <th>Create Date</th>
                      <th>Quantity</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.map((order) => (
                      <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.nameBook}</td>
                        <td>{order.createDate}</td>
                        <td>{order.quantity}</td>
                        <td>{order.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}



          {selectedCategory === 'cartItem' && (
            <div className="cart-items">
              <h2>Cart Items</h2>
              {cartItems.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Create By</th>
                      <th>Create Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.nameBook}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price}</td>
                        <td>{item.createBy}</td>
                        <td>{item.createDate}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>Your cart is empty.</p>
              )}
            </div>
          )}
          {selectedCategory === 'change-password' && (
            <div className="change-password-form">
              <h2>Change Password</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="oldPassword">Old Password:</label>
                  <input
                    type="password"
                    id="oldPassword"
                    placeholder='please inter old password'
                    value={oldPassword}
                    onChange={(event) => setOldPassword(event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="newPassword">New Password:</label>
                  <input
                    type="password"
                    id="newPassword"
                    placeholder='must be at least 8 characters long and contain at least one decimal digit'
                    value={newPassword}
                    onChange={(event) => setNewPassword(event.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                  <input
                    type="password"
                    id="confirmNewPassword"
                    placeholder='please confirm new password'
                    value={confirmNewPassword}
                    onChange={(event) => setConfirmNewPassword(event.target.value)}
                    required
                  />
                </div>
                  <p>{resultMessage}</p>
                    <button type="submit" onClick={handleChangePassword}>Submit</button>
              </form>
            </div>
        )}{selectedCategory === 'create-key' && (
          <div className="key-manager">
    <h2>Key Manager</h2>

    {/* Nút tạo Key */}
{/* Nút Tạo Key */}
      <button onClick={handleCreateKey}>Tạo Key</button>

      {/* Thông báo kết quả */}

      {/* Nút Tải Key nếu đã tạo thành công */}
      {privateKey && (
        <button onClick={handleDownloadKey}>Tải Key</button>
      )}
    <div>
  <button onClick={handleReportKey}>Báo Mất Key</button>
  {resultMessage && <p>{resultMessage}</p>}
  {newPrivateKey && (
    <button onClick={downloadPrivateKey}>Tải Key Mới</button>
  )}
</div>

  </div>
        )}
        
    
        
           
      </div>
    </div>
  );
};

export default Account;
