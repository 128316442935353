import React, { useState } from 'react';

const DownloadApp = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => setShowDropdown(true);
  const handleMouseLeave = () => setShowDropdown(false);

  return (
    <div 
      className="dropdown ms-2" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave} 
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <button className="btn btn-outline-primary">
        <span className="fa fa-download me-1"></span> Tải App Ký Xác Nhận
      </button>
      {showDropdown && (
        <div 
          className="dropdown-menu"
          style={{
            display: 'block',
            position: 'absolute',
            top: '100%',
            left: '0',
            zIndex: 1000,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '0.5rem',
          }}
        >
          <a 
            href="/downloads/AppKyXacNhan.exe" 
            className="dropdown-item text-start" 
            download
          >
            Tải cho Windows
          </a>
          <a 
            href="/downloads/KyXacNhan.dmg" 
            className="dropdown-item text-start" 
            download
          >
            Tải cho macOS
          </a>
        </div>
      )}
    </div>
  );
};

export default DownloadApp;
